import clsx from "clsx";

export const Bifold = ({ children }: { children: React.ReactNode }) => {
    return (
        <div
            className={clsx(
                "flex min-h-full flex-col justify-center lg:flex-row",
                "gap-5 lg:gap-[100px] xl:gap-[120px]",
                "[&>*:first-child]:flex [&>*:first-child]:w-full [&>*:first-child]:flex-col [&>*:first-child]:gap-3",
                "lg:[&>*:first-child]:w-[400px]",
                "[&>*:last-child]:w-full lg:[&>*:last-child]:max-w-[520px]",
                "lg:[&>*:last-child]:mb-0",
                "lg:[&>*:last-child]:fade-container",
                "[&>*:last-child]:mb-4 lg:[&>*:last-child]:pr-4",
                "lg:[&>*:last-child]:box-content",
            )}
        >
            {children}
        </div>
    );
};
