import React from "react";
import clsx from "clsx";
import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    Transition,
    Portal,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { ErrorMessage } from "./ErrorMessage";
import { Label } from "./Label";

export type OptionRawSelect = {
    label: string | React.ReactNode;
    value: any;
};

export type GroupedOptionRawSelect = {
    groupLabel: string;
    options: OptionRawSelect[];
};

type RawSelectProps = {
    testId?: string;
    name: string;
    className?: string;
    options: (OptionRawSelect | GroupedOptionRawSelect)[];
    placeholder?: string;
    label: string;
    selected: OptionRawSelect | OptionRawSelect[] | null;
    onChange: (value: OptionRawSelect | OptionRawSelect[]) => void;
    onBlur?: () => void;
    errorMessage?: string;
    required?: boolean;
    ariaDescribedby?: string;
    showErrorRing?: boolean;
    disabled?: boolean;
    multiple?: boolean;
};

export function RawSelect({
    testId,
    name,
    showErrorRing,
    placeholder = "Select an option",
    label,
    options,
    selected,
    onChange,
    errorMessage,
    required,
    ariaDescribedby,
    onBlur,
    disabled,
    multiple = false,
}: RawSelectProps) {
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const describedBy = ariaDescribedby || `${testId}-error`;
    const [position, setPosition] = React.useState(() => ({
        top: 0,
        left: 0,
        width: 0,
        isAbove: false,
    }));

    const updatePosition = React.useCallback(() => {
        if (!buttonRef.current) return;

        const rect = buttonRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const spaceBelow = viewportHeight - rect.bottom;
        const spaceAbove = rect.top;

        setPosition({
            top: rect.bottom,
            left: rect.left,
            width: rect.width,
            isAbove: spaceBelow < 300 && spaceAbove > spaceBelow,
        });
    }, []);

    React.useEffect(() => {
        const options = { capture: true };
        window.addEventListener("scroll", updatePosition, options);
        window.addEventListener("resize", updatePosition);

        return () => {
            window.removeEventListener("scroll", updatePosition, options);
            window.removeEventListener("resize", updatePosition);
        };
    }, [updatePosition]);

    return (
        <Listbox
            value={selected}
            onChange={onChange}
            disabled={disabled}
            multiple={multiple}
        >
            {({ open }) => (
                <div className="relative">
                    <Label required={required} name={name} label={label} />
                    <ListboxButton
                        ref={buttonRef}
                        aria-haspopup="listbox"
                        className={clsx(
                            "secondary relative mb-1 mt-2 w-full cursor-pointer",
                            (errorMessage || showErrorRing) &&
                                "ml-px ring-1 ring-red-600",
                            disabled && "cursor-not-allowed",
                        )}
                        data-testid={testId}
                        onBlur={onBlur}
                        onClick={updatePosition}
                        aria-describedby={describedBy}
                    >
                        {multiple ? (
                            Array.isArray(selected) && selected.length > 0 ? (
                                <div className="flex flex-wrap gap-2">
                                    {selected.map((option) => (
                                        <span
                                            key={option.value}
                                            className="rounded bg-slate-800 px-2 py-1"
                                        >
                                            {option.label}
                                        </span>
                                    ))}
                                </div>
                            ) : (
                                <p className="body-small subdued">
                                    {placeholder}
                                </p>
                            )
                        ) : selected ? (
                            <p className="body-small">
                                {(selected as OptionRawSelect).label}
                            </p>
                        ) : (
                            <p className="body-small subdued">{placeholder}</p>
                        )}
                        <span
                            className={clsx(
                                "pr-2",
                                "pointer-events-none absolute inset-y-0 right-0 flex items-center",
                            )}
                        >
                            <ChevronUpDownIcon
                                className="size-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </span>
                    </ListboxButton>
                    <Portal>
                        <Transition
                            show={open}
                            as={React.Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <div
                                className="fixed inset-0 z-50"
                                onClick={(e) =>
                                    e.target === e.currentTarget &&
                                    buttonRef.current?.click()
                                }
                            >
                                <div
                                    style={
                                        {
                                            position: "absolute",
                                            top: position.isAbove
                                                ? "auto"
                                                : position.top,
                                            bottom: position.isAbove
                                                ? `${window.innerHeight - position.top}px`
                                                : "auto",
                                            left: position.left,
                                            width: position.width,
                                            zIndex: 51,
                                        } as React.CSSProperties
                                    }
                                >
                                    <ListboxOptions
                                        className={clsx(
                                            "fixed",
                                            "max-h-[300px] overflow-auto",
                                            "rounded-md shadow-lg",
                                            "bg-white dark:bg-slate-800",
                                            "border border-slate-200 dark:border-slate-700",
                                            "custom-scrollbar",
                                            position.isAbove
                                                ? "bottom-0"
                                                : "top-0",
                                        )}
                                        style={
                                            {
                                                width: position.width,
                                                left: position.left,
                                                top: position.isAbove
                                                    ? "auto"
                                                    : position.top,
                                                bottom: position.isAbove
                                                    ? `${window.innerHeight - position.top}px`
                                                    : "auto",
                                            } as React.CSSProperties
                                        }
                                    >
                                        {options.map((optionOrGroup) =>
                                            "groupLabel" in optionOrGroup ? (
                                                <div
                                                    key={
                                                        optionOrGroup.groupLabel ||
                                                        "group-unique-key"
                                                    }
                                                >
                                                    <p
                                                        className={clsx(
                                                            "bg-secondary body-micro p-2",
                                                            "subdued font-bold uppercase",
                                                        )}
                                                    >
                                                        {
                                                            optionOrGroup.groupLabel
                                                        }
                                                    </p>
                                                    {optionOrGroup.options.map(
                                                        (option) => (
                                                            <ListboxOption
                                                                key={
                                                                    option.value
                                                                }
                                                                className={({
                                                                    focus,
                                                                }) =>
                                                                    clsx(
                                                                        focus
                                                                            ? "bg-slate-800"
                                                                            : "bg-secondary",
                                                                        "relative cursor-pointer select-none py-2 pl-10 pr-4",
                                                                    )
                                                                }
                                                                value={option}
                                                            >
                                                                {({
                                                                    selected,
                                                                    focus,
                                                                }) => (
                                                                    <>
                                                                        {typeof option.label ===
                                                                        "string" ? (
                                                                            <p className="body-small block cursor-pointer">
                                                                                {
                                                                                    option.label
                                                                                }
                                                                            </p>
                                                                        ) : (
                                                                            <div>
                                                                                {
                                                                                    option.label
                                                                                }
                                                                            </div>
                                                                        )}
                                                                        {selected ? (
                                                                            <span
                                                                                className={clsx(
                                                                                    focus
                                                                                        ? "subdued"
                                                                                        : "subdued",
                                                                                    "absolute inset-y-0 left-0 flex items-center pl-3",
                                                                                )}
                                                                            >
                                                                                <CheckIcon
                                                                                    className="size-5"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </span>
                                                                        ) : null}
                                                                    </>
                                                                )}
                                                            </ListboxOption>
                                                        ),
                                                    )}
                                                </div>
                                            ) : (
                                                <ListboxOption
                                                    key={optionOrGroup.value}
                                                    className={({ active }) =>
                                                        clsx(
                                                            active
                                                                ? "bg-slate-800"
                                                                : "bg-secondary",
                                                            "relative cursor-pointer select-none py-2 pl-10 pr-4",
                                                        )
                                                    }
                                                    value={optionOrGroup}
                                                >
                                                    {({ selected, active }) => (
                                                        <>
                                                            {typeof optionOrGroup.label ===
                                                            "string" ? (
                                                                <p className="body-small block cursor-pointer">
                                                                    {
                                                                        optionOrGroup.label
                                                                    }
                                                                </p>
                                                            ) : (
                                                                <div>
                                                                    {
                                                                        optionOrGroup.label
                                                                    }
                                                                </div>
                                                            )}
                                                            {selected ? (
                                                                <span
                                                                    className={clsx(
                                                                        active
                                                                            ? "subdued"
                                                                            : "subdued",
                                                                        "absolute inset-y-0 left-0 flex items-center pl-3",
                                                                    )}
                                                                >
                                                                    <CheckIcon
                                                                        className="size-5"
                                                                        aria-hidden="true"
                                                                    />
                                                                </span>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </ListboxOption>
                                            ),
                                        )}
                                    </ListboxOptions>
                                </div>
                            </div>
                        </Transition>
                    </Portal>
                    <div
                        className={clsx(
                            "overflow-hidden transition-all duration-300",
                            errorMessage ? "h-6 opacity-100" : "h-0 opacity-0",
                        )}
                    >
                        {errorMessage && (
                            <ErrorMessage
                                id={describedBy}
                                message={errorMessage}
                            />
                        )}
                    </div>
                </div>
            )}
        </Listbox>
    );
}
