import {
    handleErrors as onError,
    handleRespondedErrors,
} from "./errorHandlers";
import { ApiClient } from "../../types/client.types";
import { camelCased, log, snakeCased } from "@/utils";
import { httpClient } from "./httpClient";
import _ from "lodash";
import { InternalAxiosRequestConfig } from "axios";

export const createApiClient = ({
    baseURL = process.env.API_GATEWAY_URL || "",
    token,
    convert = true,
}: {
    token?: string;
    baseURL?: string;
    convert?: boolean;
} = {}): ApiClient => {
    const config: any = {
        baseURL,
        withCredentials: true,
    };
    if (token) {
        config.headers = {
            Authorization: `Bearer ${token}`,
        };
    }

    const apiClient = httpClient.create(config);

    // handle errors responded with 2xx responses.
    apiClient.interceptors.response.use(handleRespondedErrors);

    // extract data on success (only important part).
    apiClient.interceptors.response.use((res) => {
        log.info("Network Response", {
            ...res,
            headers: _.omit(res.headers, "Authorization"),
        });
        return convert ? camelCased(res.data) : res.data;
    });

    // handle network errors (4xx & 5xx responses).
    apiClient.interceptors.response.use(undefined, onError);

    // Handle both JSON and FormData requests
    apiClient.interceptors.request.use((config: InternalAxiosRequestConfig) => {
        const isFormData = config.data instanceof FormData;
        console.log("isFormData", isFormData);

        const updatedConfig: InternalAxiosRequestConfig = {
            ...config,
            params: convert ? snakeCased(config.params) : config.params,
            data:
                convert && !isFormData ? snakeCased(config.data) : config.data,
        };

        if (isFormData) {
            updatedConfig.headers.set("Content-Type", "multipart/form-data");
        } else {
            updatedConfig.headers.set("Content-Type", "application/json");
        }

        return updatedConfig;
    });

    return apiClient;
};
