import { Illustration } from "../../atoms/Illustration";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";

export function ReturnPage({
    title,
    description,
    href,
    linkText,
}: {
    title: string;
    description: string;
    href: string;
    linkText: string;
}) {
    return (
        <main
            className={clsx(
                "px-6 py-24",
                "grid min-h-full place-items-center",
                "sm:py-32 lg:px-8",
            )}
        >
            <div className="flex flex-col items-center text-center">
                <Illustration.Graphics />
                <h1 className={clsx("mt-4", "display-m", "sm:display-s")}>
                    {title}
                </h1>
                <p className="body-s mt-6 max-w-[672px]">{description}</p>
                <div
                    className={clsx(
                        "mt-10",
                        "flex items-center justify-center gap-x-6",
                    )}
                >
                    <Link
                        to={href}
                        className={clsx(
                            "mb-6",
                            "group flex items-center gap-2.5",
                        )}
                    >
                        <ChevronLeftIcon className="size-4 group-hover:fill-brand-400 group-hover:stroke-brand-400" />
                        {linkText}
                    </Link>
                </div>
            </div>
        </main>
    );
}
