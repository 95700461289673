import React, { useCallback } from "react";
import mime from "mime-types";
import { FileItem } from "./FileItem";
import clsx from "clsx";
import Dropzone from "react-dropzone";

export type DropZoneProps = {
    title: string;
    file?: File;
    onFileUpload?: (file: File) => void;
    onRemove: () => void;
    className?: string;
    testid?: string;
    extensions?: string[];
    label?: string;
    description?: string;
};

export const DropZone = ({
    title,
    label,
    file,
    onFileUpload,
    onRemove,
    className,
    testid,
    extensions,
    description,
}: DropZoneProps) => {
    const onDrop = useCallback(
        (acceptedFiles) => {
            onFileUpload && onFileUpload(acceptedFiles[0]);
        },
        [file],
    );

    let allowedExtensions = label ? label : "All formats accepted";
    if (extensions && !label) {
        if (extensions.length > 1) {
            allowedExtensions = `${extensions
                .slice(0, -1)
                .map((e) => e.toLocaleUpperCase())
                .join(", ")} or ${extensions
                .slice(-1)[0]
                .toLocaleUpperCase()} files only`;
        } else {
            allowedExtensions = `${extensions[0].toLocaleUpperCase()} files only`;
        }
    }

    const accept = extensions
        ? extensions
              .map((extension) => mime.types[extension])
              .reduce(
                  (types, mimetype) => ({
                      ...types,
                      [mimetype]: mime.extensions[mimetype].map((t) => "." + t),
                  }),
                  {},
              )
        : extensions;

    return (
        <div className={className} data-testid={testid}>
            <div className="rounded">
                <h2 className="heading-s mb-2">{title}</h2>
                <p className="body-s mb-8">{description}</p>

                {file ? (
                    <FileItem file={file} onRemove={onRemove} />
                ) : (
                    <>
                        <Dropzone onDrop={onDrop} maxFiles={1} accept={accept}>
                            {({
                                getRootProps,
                                getInputProps,
                                isDragActive,
                                isFocused,
                                fileRejections,
                            }) => (
                                <section>
                                    <div
                                        {...getRootProps({
                                            className: clsx(
                                                isDragActive || isFocused
                                                    ? "border-gray-500 bg-gray-600"
                                                    : "bg-surface-subdued border-borderColor",
                                                "mt-4",
                                                "body-s border-default rounded border border-dashed",
                                                "flex min-h-[11.5rem] flex-col items-center justify-center",
                                            ),
                                        })}
                                    >
                                        <input {...getInputProps()} />
                                        <span className="text-center">
                                            Drop files or <a>browse</a>
                                            <p className="ui-label subdued mb-2">
                                                {allowedExtensions}
                                            </p>
                                        </span>
                                    </div>
                                    {fileRejections.length > 0 && (
                                        <p className="ui-element mt-4 break-words bg-red-500">
                                            {
                                                fileRejections[0].errors[0]
                                                    .message
                                            }
                                        </p>
                                    )}
                                </section>
                            )}
                        </Dropzone>
                    </>
                )}
            </div>
        </div>
    );
};
