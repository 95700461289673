import { useSession } from "@/entities/Session";
import { Navigate } from "react-router-dom";
import { MaintenanceBanner, Spinner } from "@/ui";
import { useFlags } from "@/utils";
import { RoleController } from "./RoleController";
import { useFlagsmith } from "flagsmith/react";
import { trackUserIdentity } from "@/entities/User";
import { useEffect } from "react";
import { useCompany } from "@/apps/CompanyApp/hooks/useCompany";
import { useApiMutation } from "@/api";
import { acceptCompanyAgreements } from "@/entities/Company";

export function SessionController() {
    const flagsmith = useFlagsmith();
    const flags = useFlags();
    const session = useSession();
    const { mutate: acceptAgreements } = useApiMutation(
        acceptCompanyAgreements,
    );
    const { company } = useCompany();
    const companyAcceptedAgreements =
        company?.acceptedTos || company?.acceptedPrivacyPolicy;
    const banner = flags.enabled("maintenance-banner")
        ? (flags.value("maintenance-banner") as Record<string, any>)
        : null;
    const isAuth0Error =
        session.isAuth0Error || (!session.token.data && session.token.isError);

    useEffect(() => {
        if (flags.ready) {
            trackUserIdentity(company, session.user, flagsmith);
        }
    }, [session.user, flagsmith, flags]);

    useEffect(() => {
        if (company?.uuid && !companyAcceptedAgreements) {
            acceptAgreements({ organizationId: company?.uuid });
        }
    }, [company?.uuid, companyAcceptedAgreements]);

    if (
        !flags.ready ||
        (session.isLoading &&
            !session.user &&
            !isAuth0Error &&
            !session.isHumError)
    )
        return <Spinner fullScreen testId="session-spinner" />;

    if (!session.isAuthenticated || isAuth0Error)
        return <Navigate to="/login" />;

    if (session.isHumError) return <Navigate to="/error?type=auth" />;

    return (
        <>
            {flags.enabled("maintenance-banner") && (
                <MaintenanceBanner
                    title={banner?.title}
                    message={banner?.message}
                />
            )}

            <RoleController user={session.user} flags={flags} />
        </>
    );
}
